import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';
import Button from '../../../01_atoms/Button';
import Slider from '../../../01_atoms/Slider';
import ItemWithImage from '../../../02_molecules/Item/ItemWithImage';
import ItemWithImageHoverable from '../../../02_molecules/Item/ItemWithImageHoverable';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

class BBFeaturedNews extends React.Component {
  sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: this.onSlideChange.bind(this),
    beforeChange: this.onSlideChange.bind(this),
  };

  constructor(props) {
    super(props);

    this.state = {
      activeSlideIndex: 0,
    };
  }

  /**
   * Handles slide change event for mobile viewport.
   */
  onSlideChange(index) {
    this.setState({ activeSlideIndex: index });
  }

  render() {
    const { title, button, items, behaviorSettings, uuid } = this.props;
    const { activeSlideIndex } = this.state;

    const classes = [
      'bb',
      'bb-featured-news',
      styles['bb-featured-news'],
      generateClassNameByBehaviorSettings(behaviorSettings),
    ];

    // When component is not yet mounted we don't know the window width yet.
    // Therefore, we don't know if the items should be rendered as a slider
    // (for mobile) or as a grid (for tablet / desktop). See
    // componentDidMount() for more info.

    return (
      <div className={classes.join(' ')} id={uuid}>
        <div className="container">
          {title && <h2 className="large">{title}</h2>}

          <Slider {...this.sliderSettings} className="d-md-none">
            {items.map((item) => (
              <ItemWithImage {...item} key={item.title} />
            ))}
          </Slider>
          <div
            className={`counter ${
              button && button.label && button.nextLink ? 'with-button' : ''
            } d-md-none`}
          >
            {activeSlideIndex + 1}/{items.length}
          </div>

          <div className="row d-none d-md-flex">
            {items.map((item, index) => (
              <div className={`col-md-${index === 0 ? '8' : '4'}`} key={item.title}>
                {index === 0 && <ItemWithImageHoverable {...item} />}
                {index > 0 && <ItemWithImage {...item} />}
              </div>
            ))}
          </div>

          {button && button.label && button.nextLink && (
            <div className="text-center">
              <Link {...button.nextLink}>
                <Button
                  isMobileBlock
                  tag="a"
                  href={button.nextLink.url}
                  type="secondary"
                  isOutlined
                  className="bb-featured-news--button"
                >
                  {button.label}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

BBFeaturedNews.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      image: PropTypes.shape(picturePropTypes),
      timestamp: PropTypes.number.isRequired,
      nextLink: PropTypes.shape(linkPropTypes).isRequired,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

BBFeaturedNews.defaultProps = {
  button: null,
  behaviorSettings: null,
  uuid: null,
};

export default BBFeaturedNews;
