import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import { TagIcon, TagText } from '../../../01_atoms/Tag';
import DefaultPicture, { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';

import { formattedDate } from '../../../../utils/date-formatter.js';
import FormattedText from '../../../01_atoms/FormattedText';

const ItemWithImageHoverable = ({
  title = null,
  description = null,
  image,
  nextLink,
  type = null,
  tags = [],
  timestamp = null,
}) => (
  <div className={`item-with-image-hoverable ${styles['item-with-image-hoverable']}`}>
    <Link {...nextLink}>
      <a className="item" title={image ? image.alt : ''}>
        {image && <DefaultPicture loading="lazy" {...image} />}

        <div className="hoverable">
          <div className="top-line">
            {type && (
              <>
                <TagText label={type} />
                {typeof tags !== 'undefined' &&
                  tags.map((tag, i) => (
                    <TagIcon
                      key={`${tag}-${i}` /* eslint-disable-line react/no-array-index-key */}
                      type={tag}
                    />
                  ))}
              </>
            )}

            {timestamp && <mark className="date">{formattedDate(timestamp)}</mark>}
          </div>

          {title && <h3 className="title">{title}</h3>}

          {description && <FormattedText className="description" text={description} />}
        </div>
      </a>
    </Link>
  </div>
);

ItemWithImageHoverable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape(picturePropTypes).isRequired,
  nextLink: PropTypes.shape(linkPropTypes).isRequired,
  type: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  timestamp: PropTypes.number,
};

export default ItemWithImageHoverable;
